import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import deleteIcon from 'assets/img/icons/remove.png';
import { useMapList } from './helper';
import rickshawPin from 'assets/img/rickshaw.png';
import hotelPin from 'assets/img/hotel.png';
import carPin from 'assets/img/car.png';
import bikePin from 'assets/img/bike.png';
import defaultPin from 'assets/img/map-arrow.png';
import './MapLocation.css';

const libraries = ['places', 'drawing'];

const MapLocation = () => {
  const { mapList } = useMapList();
  const mapRef = useRef();
  const drawingManagerRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDL28BwaoZ9H-Grr1YadDA5qDv3109JsYY',
    libraries
  });

  const [center, setCenter] = useState({ lat: 30.3753, lng: 69.3451 });
  const [bounds, setBounds] = useState(null);
  const [infoWindow, setInfoWindow] = useState({ isOpen: false, position: null, content: null });

  useEffect(() => {
    if (mapList['driver'] && mapList['driver'].length > 0) {
      const parsedCoordinates = mapList['driver'].map(coord => ({
        lat: Number(coord['latitude']),
        lng: Number(coord['longitude'])
      }));

      setCenter(parsedCoordinates[0]);

      const markerBounds = new window.google.maps.LatLngBounds();
      parsedCoordinates.forEach(coord =>
        markerBounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng))
      );
      setBounds(markerBounds);
    }
  }, [mapList]);

  const containerStyle = {
    width: '60%',
    height: '60vh'
  };

  const deleteIconStyle = {
    cursor: 'pointer',
    backgroundImage: `url(${deleteIcon})`,
    height: '24px',
    width: '24px',
    marginTop: '5px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '2px',
    left: '28%',
    zIndex: 99999
  };

  const onLoadMap = map => {
    mapRef.current = map;
    if (bounds) {
      map.fitBounds(bounds);
    }
  };

  const pinLogo = driver => {
    if(driver.role == 1)
    {
      return defaultPin;
    }
    const serviceType = driver.vehicleDetails.vehicleService[0].service_type;
    if (serviceType === 'bike') {
      return bikePin; // If service type is 'moto', return the bike pin
    } else if (serviceType === 'car' || serviceType === 'car_per_day') {
      return carPin; // If service type is 'car' and 'car_per_day' is true, return the car pin
    } else if (serviceType === 'rickshaw') {
      return rickshawPin; // If service type is 'rickshaw', return the rickshaw pin
    } else {
      return defaultPin; // For all other cases, return the default pin
    }
  };

  const handleDriverMarkerClick = (coord) => {
    const role = coord.role;
    if (role === 1) {
      const content = (
      <div className="info-window-card">
        <h4>{coord.first_name} {coord.last_name}</h4>
        <a href={`/rider-list/`} target="_blank" rel="noopener noreferrer">View Customer Details</a>
      </div>
    );
    setInfoWindow({
      isOpen: true,
      position: { lat: Number(coord.latitude), lng: Number(coord.longitude) },
      content,
    });
  }
  else{
    const content = (
      <div className="info-window-card">
        <h4>{coord.first_name} {coord.last_name}</h4>
        <a href={`/rider-list/${coord.id}`} target="_blank" rel="noopener noreferrer">View Driver Details</a>
      </div>
    );

    setInfoWindow({
      isOpen: true,
      position: { lat: Number(coord.latitude), lng: Number(coord.longitude) },
      content,
    });
  }};

  const handlePropertyOwnerMarkerClick = (coord) => {
    const content = (
      <div className="info-window-card">
        <h4>{coord.property_name}</h4>
        <a href={`/property-owner-details/${coord.user_id}`} target="_blank" rel="noopener noreferrer">View Property Details</a>
      </div>
    );

    setInfoWindow({
      isOpen: true,
      position: { lat: Number(coord.property_latitude), lng: Number(coord.property_longitude) },
      content,
    });
  };

  const darkMapStyle = [
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [{ color: '#2c2c2c' }]
    }
  ];

  return isLoaded ? (
    <div className="map-container" style={{ position: 'relative' }}>
      {drawingManagerRef.current && (
        <div title="Delete shape" style={deleteIconStyle}></div>
      )}
      <GoogleMap
        zoom={8}
        center={center}
        onLoad={onLoadMap}
        bounds={bounds}
        mapContainerStyle={containerStyle}
        onTilesLoaded={() => setCenter(null)}
        options={{ styles: darkMapStyle }}
      >
        {mapList['driver'] &&
          mapList['driver'].map((coord, markerIndex) => (
            <Marker
              key={markerIndex}
              icon={{
                url: pinLogo(coord),
                scaledSize: new window.google.maps.Size(30, 30)
              }}
              position={{
                lat: Number(coord['latitude']),
                lng: Number(coord['longitude'])
              }}
              onClick={() => handleDriverMarkerClick(coord)}
            />
          ))}
        {mapList['property_owners'] &&
          mapList['property_owners'].map((coord, markerIndex) => (
            <Marker
              key={markerIndex}
              icon={{
                url: hotelPin,
                scaledSize: new window.google.maps.Size(30, 30)
              }}
              position={{
                lat: Number(coord['property_latitude']),
                lng: Number(coord['property_longitude'])
              }}
              onClick={() => handlePropertyOwnerMarkerClick(coord)}
            />
          ))}
        {infoWindow.isOpen && (
          <InfoWindow
            position={infoWindow.position}
            onCloseClick={() => setInfoWindow({ ...infoWindow, isOpen: false })}
          >
            {infoWindow.content}
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  ) : null;
};

export default MapLocation;
